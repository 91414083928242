<template>
  <ion-page class="page white">
    <h1 class="forget-pass-title">Forgot Password?</h1>
    <p>Please enter your email address. We will email you with info on how to reset your password.</p>
    <forget-password-form class="forget-password-form"/>
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-property-decorator';
import ForgetPasswordForm from './components/ForgetPasswordForm.vue';

@Options({
  name: 'ForgetPasswordPage',
  components: {
    ForgetPasswordForm,
  },
})
export default class ForgetPasswordPage extends Vue {}
</script>

<style scoped lang="sass">
.page
  text-align: center
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  padding: 20px
  *
    font-size: 0.9rem
.logo
  height: 50px
.forget-password-form
  text-align: start
  width: 100%
  margin-top: 16px
  .forget-password-form
    margin-top: 16px
.forget-pass-title
  font-size: 26px
</style>
