<template>
  <form class="forget-password-form" @submit.prevent="sendResetEmail">
    <ion-input
      v-model.trim="usernameOrEmail"
      class="c-input"
      placeholder="Email Address"
      autocapitalize="off"
      type="email"
    />
    <ion-custom-button
      :loading="loading"
      :disabled="requestingEmail || !usernameOrEmail || loading"
      class="reset-button"
      type="submit"
    >
      Reset password
    </ion-custom-button>
    <ion-custom-button
      color="light"
      class="back-button"
      type="submit"
      @click="navigateToLogin"
    >
      Back
    </ion-custom-button>
  </form>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-property-decorator'
import { toast } from '@/shared/native'
import { resetPassword } from '@/shared/services/auth'
import IonCustomButton from '@/shared/components/ion-custom-button.vue'

@Options({
  name: 'ForgetPasswordForm',
  components: {
    IonCustomButton,
  },
})
export default class ForgetPasswordForm extends Vue {
  public requestingEmail = false
  public usernameOrEmail = ''
  public loading = false

  public check() {
    return Boolean(
      this.requestingEmail ||
        !this.usernameOrEmail ||
        !this.usernameOrEmail.trim()
    )
  }

  public navigateToLogin() {
    const router = useRouter()
    router.push('/login')
  }

  public async handleError(e: any) {
    this.loading = false
    let message = 'Something went wrong when requesting the email.'
    try {
      if (e.response.status === 404) {
        message = 'Invalid email address'
      } else if (e.response && e.response.data[0]) {
        message =
          e.response.data[0] +
          '. Try again later or email admin@characterhub.com'
      } else if (e.response && e.response.data.detail) {
        message = e.response.data.detail
      }
    } catch {}
    await toast.show(message, 'nonative', 'danger')
  }

  public isEmail() {
    return this.usernameOrEmail.includes('@')
  }

  public async sendResetEmail() {
    if (this.check()) return
    try {
      this.loading = true
      this.requestingEmail = true
      await resetPassword(this.usernameOrEmail)

      const message =
        'An email with password reset instructions has been sent to your email address. Please check your email.'
      await toast.show(message, 'nonative', 'success')
      setTimeout(() => {
        const router = useRouter()
        router.push('login')
      }, 1000)
    } catch (e) {
      return this.handleError(e)
    } finally {
      this.loading = false
      this.requestingEmail = false
    }
  }
}
</script>

<style lang="sass" scoped>
.c-input
  margin-bottom: 16px
</style>
